@import "../../assets/scss/helpers/colors";


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */

.MuiIcon-root.flag-icon {
  height: 1.4em;
}
