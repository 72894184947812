@import "../../assets/scss/helpers/colors";

/*--------------------------------------------------------------
# Presentation Section
--------------------------------------------------------------*/
#presentation {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 140px 0 100px 0;
  margin-bottom: -75px;

  &::before {
    content: "";
    position: absolute;
    right: -100%;
    top: 20%;
    width: 250%;
    height: 200%;
    z-index: -1;
    background-color: #e8ecf5;
    transform: skewY(135deg);
  }

  h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: $primaryColor;
    font-family: "Poppins", sans-serif;
  }

  h2 {
    color: #515f7d;
    margin-bottom: 50px;
    font-size: 20px;
  }

  .download-btn {
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    height: 75px;
    width: 218px;
    display: inline-block;
    padding: 8px 24px 10px 46px;
    border-radius: 3px;
    transition: 0.5s;
    position: relative;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    .ios-btn {
      background: url(https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg) 0% 0% / contain no-repeat;
      background-size: 100% 70%;
      background-position: center;
    }

    .android-btn {
      background: url("https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png") 0% 0% / contain no-repeat;
    }
  }
}
@media (max-width: 991px) {
  #presentation {
    text-align: center;

    .download-btn + .download-btn {
      margin: 0 10px;
    }
    .presentation-img {
      text-align: center;

      img {
        width: 60%;
      }
    }
  }
}

@media (max-width: 768px) {
  #presentation h1 {
    font-size: 28px;
    line-height: 36px;

    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    .presentation-img img {
      width: 70%;
    }
  }
}

@media (max-width: 575px) {
  #presentation .presentation-img img {
    width: 80%;
  }
}
